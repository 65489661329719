<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
/* eslint-disable no-debugger */

import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "AppLayout",
  setup() {
    const layout = shallowRef();
    const route = useRoute();
    watch(
      () => route.meta,
      async (meta) => {
        try {
          const component = await require(`@/layouts/${meta.layout}.vue`);
          layout.value = component?.default;
        } catch (e) {
          const component = await require(`@/layouts/AppLayoutDefault.vue`);
          layout.value = component?.default;
        }
      }
    );
    return { layout };
  },
};
</script>
