<template>
  <img
    v-if="user?.avatar?.url"
    :class="[...className, 'object-cover']"
    :src="VUE_APP_API_URL + user.avatar?.url"
    alt="User Profile"
  />
  <img
    v-else
    :class="[...className, 'object-cover']"
    :src="`https://source.boringavatars.com/beam/120/${user?.email}?colors=0C4A6E,0284C7,D1FAE5,0284C7,e76f51`"
    alt="User Profile"
  />
</template>
  
<script>
export default {
  setup() {
    return {
      VUE_APP_API_URL: process.env.VUE_APP_API_URL,
    };
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          email: "",
          avatar: {
            url: "",
          },
        };
      },
    },
    className: {
      type: Array,
    },
  },
};
</script>

