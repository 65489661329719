import App from './App.vue';
import { createApp } from 'vue';
import router from './router';
import store from './store/index';
import Toast from 'vue-toastification';
import AppLayout from './layouts/AppLayout';
import 'vue-toastification/dist/index.css';
import './assets/tailwind.css';

createApp(App)
  .use(router)
  .use(store)
  .use(Toast)
  .component('AppLayout', AppLayout)
  .mount('#app');
