import axios from 'axios';
// import router from '@/router';
import { useToast } from 'vue-toastification';
import { getAuthToken } from './auth';
const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
});

const toast = useToast();

const errorInterceptor = (error) => {
  // check if it's a server error
  if (!error.response) {
    toast.error('Network/Server error');
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {
    case 500:
      toast.error(
        Array.isArray(error.response.data.message)
          ? error.response.data.message[0].messages[0].message
          : error.response.data.message
      );
      break;
    case 400:
      toast.error(
        Array.isArray(error.response.data.message)
          ? error.response.data.message[0].messages[0].message
          : error.response.data.message
      );
      break;
    case 401: // authentication error, logout the user
      toast.error(
        Array.isArray(error.response.data.message)
          ? error.response.data.message[0].messages[0].message
          : error.response.data.message
      );
      break;
    case 404: // Not found error
      toast.error(
        Array.isArray(error.response.data.message)
          ? error.response.data.message[0].messages[0].message
          : error.response.data.message
      );
      break;
  }
  return Promise.reject(error);
};

httpClient.interceptors.response.use(undefined, errorInterceptor);

httpClient.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.headers['Authorization'] = 'Bearer ' + getAuthToken();
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default httpClient;
