import {
  createCaseNote,
  getCase,
  getCases,
  getCasesCount,
  getAllLegelFees,
  getLegelFees,
  getLegelFeesCount,
  getMountlyCasesCount,
  getMyCases,
  createLegalFee,
  getLegalFee,
  updateLegalFee,
  deleteLegalFee,
  createEmailsLog,
  getEmails,
  getEmailsCount
} from '../../services/case';

const state = {
  sell_case: {
    amount: '',
    app_type: '',
    is_mortgage: false,
    property_type: '',
    address: {
      street_address: '',
      postcode: '',
      city: '',
      country: '',
      coords: '{ "lat": 52.024182, "lng": -1.0654541 }',
    },
  },
  purchase_case: {
    amount: '',
    app_type: '',
    is_mortgage: false,
    property_type: '',
    address: {
      street_address: '',
      postcode: '',
      city: '',
      country: '',
      coords: '{ "lat": 52.024182, "lng": -1.0654541 }',
    },
  },
  property: '',
  personal: {},
  cases: {
    items: [],
    count: 0,
  },
  emails: {
    items: [],
    count: 0
  },
  dashboard: {
    cases: 0,
    mountlyCases: 0,
  },
  case: {},
  case_notes: [],
};

// getters
const getters = {};

// actions
const actions = {
  updateCase({ commit }, payload) {
    commit('UPDATE_SELLCASE', payload.sell_case);
    commit('UPDATE_PURCHASECASE', payload.purchase_case);
  },
  updateProperty({ commit }, payload) {
    commit('UPDATE_PROPERTY', payload.type);
    commit('UPDATE_PROPERTY_TYPES', payload.types);
  },
  updateBuyer({ commit }, payload) {
    commit('UPDATE_BUYER', payload.type);
  },
  updatePersonal({ commit }, payload) {
    commit('UPDATE_PERSONAL', payload);
  },
  // updateLegalFee({ commit }, payload) {
  //   commit('UPDATE_LEGALFEE', payload);
  // },
  async getCases({ commit, state }, start) {
    console.log("start", start)
    let payload = {
      start: start?.start || 0,
      limit: start?.limit || 10,
      id: start?.id || undefined
    }
    const { data } = await getCases(payload);
    let count = state.cases.count;
    if (!(count > 0)) {
      const { data: caseCount } = await getCasesCount(payload.id);
      count = caseCount;
    }
    commit('SET_CASES', { items: data, count });
  },
  async getCase({ commit }, id) {
    const { data } = await getCase(id);
    commit('SET_CASE', data);
  },
  async getMyCases({ commit }) {
    const { data } = await getMyCases();
    commit('SET_CASES', { items: data, count: 0 });
    return data;
  },
  async createCaseNote({ dispatch }, data) {
    await createCaseNote({
      note: data.note,
      case: data.caseId,
    });
    await dispatch('getCase', data.caseId);
  },
  async getDashboardValues({ commit }) {
    const { data: totalCases } = await getCasesCount();
    const { data: totalMountlyCases } = await getMountlyCasesCount();
    commit('SET_DASHBOARD_VALUES', { totalCases, totalMountlyCases });
  },
  async getLegelFees({ commit, state }, start) {
    const { data } = await getLegelFees(start);
    let count = state.cases.count;
    if (!(count > 0)) {
      const { data: legelFeeCount } = await getLegelFeesCount();
      count = legelFeeCount;
    }
    commit('SET_LEGEL_FEES', { items: data, count });
  },
  async getAllLegelFees({ commit, state }) {
    const { data } = await getAllLegelFees();
    let count = state.cases.count;
    if (!(count > 0)) {
      const { data: legelFeeCount } = await getLegelFeesCount();
      count = legelFeeCount;
    }
    commit('SET_LEGEL_FEES', { items: data, count });
  },
  async createLegalFee({ dispatch }, data) {
    await createLegalFee({
      name: data.name,
      price_from: data.price_from,
      price_to: data.price_to,
      fees: data.fees,
      admin: data.admin,
      buyer: data.buyer,
      type_of_sale: data.type_of_sale,
      land_registry_fee: data.land_registry_fee,
      land_registry_search: data.land_registry_search,
      search_packs: data.search_packs,
      vat_fee: data.vat_fee,
      mortgage_fee: data.mortgage_fee,
      id_verfication_fee: data.id_verfication_fee,
      electronic_transfer_fee: data.electronic_transfer_fee,
      archive_fee: data.archive_fee,
      lawyer_fee: data.lawyer_fee,
      storage_fee: data.storage_fee,
      mortgage_discharge_fee: data.mortgage_discharge_fee,
      draft_contract_fee: data.draft_contract_fee,
      ownership_fee: data.ownership_fee,
      bankruptcy_fee: data.bankruptcy_fee,
      transfer_fee: data.transfer_fee,
    });
    await dispatch('getLegelFees', data.caseId);
  },
  async getLegalFee({ commit }, id) {
    const { data } = await getLegalFee(id);
    commit('SET_LEGAL_DATA', data);
  },

  async updateLegalFee({ dispatch }, data) {
    // console.log("legal-data-update", data)
    await updateLegalFee({
      name: data.name,
      price_from: data.price_from,
      price_to: data.price_to,
      fees: data.fees,
      admin: data.admin,
      buyer: data.buyer,
      type_of_sale: data.type_of_sale,
      land_registry_fee: data.land_registry_fee,
      land_registry_search: data.land_registry_search,
      search_packs: data.search_packs,
      vat_fee: data.vat_fee,
      mortgage_fee: data.mortgage_fee,
      id_verfication_fee: data.id_verfication_fee,
      electronic_transfer_fee: data.electronic_transfer_fee,
      archive_fee: data.archive_fee,
      lawyer_fee: data.lawyer_fee,
      storage_fee: data.storage_fee,
      mortgage_discharge_fee: data.mortgage_discharge_fee,
      draft_contract_fee: data.draft_contract_fee,
      ownership_fee: data.ownership_fee,
      bankruptcy_fee: data.bankruptcy_fee,
      transfer_fee: data.transfer_fee,
      id: data.id
    });
    await dispatch('getLegelFees', data.caseId);
  },

  async deleteLegalFee({ dispatch }, id) {
    const { data } = await deleteLegalFee(id);
    await dispatch('getLegelFees', data.caseId);
  },
  async getEmails({ commit, state }, start) {
    const { data } = await getEmails(start);
    let count = state.emails.count;
    console.log("count", count)
    if (!(count > 0)) {
      const { data: emailCount } = await getEmailsCount();
      count = emailCount;
    }
    commit('SET_EMAILS', { items: data, count });
  },
  async createEmailsLogs({ dispatch }, data) {
    // console.log("modules>>>", data)
    await createEmailsLog({
      client_name: data.client_name,
      client_email: data.client_email,
      subject: data.subject,
      text: data.text,
      type_of_sale: data.type_of_sale,
      property_price: data.property_price,
      discount_fee: data.discount_fee,
      preview_price: data.preview_price,
      legel_fees: data.legel_fees,
      land_registry_fee: data.land_registry_fee,	
      land_registry_search: data.land_registry_search,
      search_packs: data.search_packs,
      mortgage_fee:  data.mortgage_fee,
      vat_fee:   data.vat_fee,
      id_verfication_fee: data.id_verfication_fee,
      electronic_transfer_fee:  data.electronic_transfer_fee,
      archive_fee:  data.archive_fee,
      lawyer_fee:  data.lawyer_fee,
      sdlt_fee: data.sdlt_fee,
      stamp_duty_fee: data.stamp_duty_fee
    });
    await dispatch('getLegelFees', data.caseId);

  },
};

// mutations
const mutations = {
  UPDATE_CASE(state, payload) {
    state.case = payload;
  },
  UPDATE_PROPERTY(state, payload) {
    state.property = payload;
  },
  UPDATE_PROPERTY_TYPES(state, payload) {
    state.sell_case.app_type = payload.find((i) => i.uid === 'sale').id;
    state.purchase_case.app_type = payload.find((i) => i.uid === 'purchase').id;
  },
  UPDATE_BUYER(state, payload) {
    state.buyer = payload;
  },
  UPDATE_SELLCASE(state, payload) {
    state.sell_case = payload;
  },
  UPDATE_PURCHASECASE(state, payload) {
    state.purchase_case = payload;
  },
  UPDATE_PERSONAL(state, payload) {
    state.personal = payload;
  },
  // UPDATE_LEGALFEE(state, payload) {
  //   state.personal = payload;
  // },
  SET_CASES(state, payload) {
    state.cases = payload;
  },

  SET_EMAILS(state, payload) {
    state.emails = payload;
  },
  SET_STAFF_CASES(state, payload) {
    state.staff_cases = payload;
  },
  SET_CASE(state, payload) {
    state.case = payload;
  },
  SET_LEGEL_FEES(state, payload) {
    state.legalFee = payload;
  },
  SET_LEGEL_FEE(state, payload) {
    state.legalFee = payload;
  },
  SET_CASE_NOTES(state, paylad) {
    state.case_notes = paylad;
  },
  SET_DASHBOARD_VALUES(state, payload) {
    state.dashboard = payload;
  },
  SET_LEGAL_DATA(state, payload) {
    state.legalData = payload;
  },
  SET_EMAIL_LOG(state, payload) {
    state.emailLog = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
