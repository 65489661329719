import { createStore, createLogger } from 'vuex';
import action from './modules/action';
import user from './modules/user';
import cases from './modules/case';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    action,
    user,
    cases,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
