import {
  getRecentUpdateCount,
  getRecentUpdates,
  getSignatureTypes,
  getUpdates,
  getUpdateTypes,
  updateAction,
} from '../../services/action';
import { useToast } from 'vue-toastification';

const toast = useToast();

const state = {
  updates: [],
  update_types: [],
  signature_types: [],
  recent_updates: {
    items: [],
    count: 0,
  },
};

// getters
const getters = {
  completedActions: (state) => {
    return state.updates.filter(
      (i) =>
        i.update_status?.uid === 'completed' &&
        i.update_type?.uid !== 'information'
    );
  },
  activeActions: (state) => {
    return state.updates.filter(
      (i) =>
        i.update_status?.uid !== 'completed' &&
        i.update_type?.uid !== 'information'
    );
  },
  actionsCount: (state) => {
    return state.updates.filter(
      (i) =>
        i.update_status?.uid !== 'completed' &&
        i.update_type?.uid !== 'information'
    ).length;
  },
};

// actions
const actions = {
  async completeAction({ dispatch }, payload) {
    await updateAction(payload.id, {
      update_status: 'completed',
      ...payload.data,
    });
    toast.success('Action Completed!');
    dispatch('getUpdates', 1);
  },
  async pendingAction({ dispatch }, payload) {
    await updateAction(payload.id, {
      update_status: 'pending-for-verification',
      ...payload.data,
    });
    dispatch('getUpdates', 1);
  },
  async getUpdateTypes({ commit }) {
    const { data } = await getUpdateTypes();
    commit('SET_UPDATE_TYPES', data);
  },
  async getSignatureTypes({ commit }) {
    const { data } = await getSignatureTypes();
    commit('SET_SIGNATURE_TYPES', data);
  },
  async getUpdates({ commit }, payload) {
    const { data } = await getUpdates(payload.isAction);
    data.sort(function(a, b) {
      return a.update_type.uid === 'verification'
        ? -1
        : b.update_type.uid == 'verification'
        ? 1
        : 0;
    });
    commit('SET_UPDATES', data);
  },
  async getRecentUpdates({ commit }, start) {
    const { data } = await getRecentUpdates(start);
    const { data: count } = await getRecentUpdateCount();
    commit('SET_RECENT_UPDATES', { items: data, count });
  },
};

// mutations
const mutations = {
  COMPLETE_ACTION(state, items) {
    state.actions = items;
  },
  SET_UPDATE_TYPES(state, items) {
    state.update_types = items;
  },
  SET_SIGNATURE_TYPES(state, items) {
    state.signature_types = items;
  },
  SET_UPDATES(state, items) {
    state.updates = items;
  },
  SET_RECENT_UPDATES(state, payload) {
    state.recent_updates = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
