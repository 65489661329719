<template>
  <main class="lg:relative grid">
    <header class="h-20 bg-light-blue-900 grid place-content-center">
      <a href="/">
        <img src="@/assets/logo-white-trans.png" />
      </a>
    </header>
    <div class="w-full">
      <slot />
    </div>
  </main>
</template>

