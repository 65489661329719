import { clearAuthToken, registerUser, updateMe } from '../../services/auth';
import { getClient, getClients, getClientsCount, getStaff, getStaffs, getStaffsCount, getCaseStaffs, getCaseStaffsCount, createStaff, updateStaff, deleteStaff, addCaseStaff, removeCaseStaff } from '../../services/user';

const state = {
  user: {
    role: {
      name: '',
    },
  },
  clients: {
    items: [],
    count: 0,
  },
  client: {},
  staffs: {
    items: [],
    count: 0,
  },
  staff: {},
};

// getters
const getters = {
  user: (state) => {
    return state.user;
  },
};

// actions
const actions = {
  setUser({ commit }, user) {
    localStorage.setItem('r', user.role.name);
    localStorage.setItem('auth-user', JSON.stringify(user));
    commit('SET_USER', user);
  },
  logOut({ commit }) {
    commit('LOGOUT');
    clearAuthToken();
    window.location.href = '/login';
  },
  async registerUser({ commit }, payload) {
    const resp = await registerUser(payload);
    console.log(resp, commit);
  },
  async getClients({ commit }, payload) {
    const { data } = await getClients({
      start: payload?.start || 0,
      limit: payload?.limit || 10,
      search: payload?.search || '',
    });
    const { data: count } = await getClientsCount(payload?.search);
    commit('SET_CLIENTS', { items: data, count });
  },
  async getStaffs({ commit }, payload) {
    const { data } = await getStaffs({
      start: payload?.start || 0,
      limit: payload?.limit || 10,
      search: payload?.search || '',
    });
    const { data: count } = await getStaffsCount(payload?.search);
    commit('SET_STAFFS', { items: data, count });
  },
  async getCaseStaffs({ commit }, payload) {
    const { data } = await getCaseStaffs({
      caseId: payload || ''
    });
    const { data: count } = await getCaseStaffsCount(payload);
    commit('SET_CASE_STAFFS', { items: data, count });
  },
  async createStaff({ dispatch }, data) {
    await createStaff({
      first_name: data.first_name,
      last_name: data.last_name,
      username: data.username,
      email: data.email,
      phone: data.phone,
      password: data.password,
      street_address: data.street_address,
      city: data.city,
      state: data.state,
      country: data.country,
      postcode: data.postcode
    });
    await dispatch('getStaffs');
  },
  async updateStaff({ dispatch }, data) {
    let obj = {
      first_name: data.first_name,
      last_name: data.last_name,
      username: data.username,
      email: data.email,
      phone: data.phone,
      id: data.id,
      street_address: data.street_address,
      city: data.city,
      state: data.state,
      country: data.country,
      postcode: data.postcode
    }
    if (data.password !== "") {
      obj.password = data.password
    }
    await updateStaff(obj);
    await dispatch('getStaffs');
  },
  async addCaseStaff({ dispatch }, data) {
    console.log("staff,caseId", data)
    await addCaseStaff(data.staff, data.caseId);
    await dispatch('getStaffs');
  },
  async removeCaseStaff({ dispatch }, data) {
    console.log("staff,caseId", data)
    await removeCaseStaff(data.staff, data.caseId);
    await dispatch('getStaffs');
  },
  async deleteStaff({ dispatch }, id) {
    await deleteStaff(id);
    await dispatch('getStaffs');
  },
  async getClient({ commit }, payload) {
    const { data } = await getClient(payload);
    commit('SET_CLIENT', data);
  },
  async getStaff({ commit }, payload) {
    const { data } = await getStaff(payload);
    commit('SET_STAFF', data);
  },
  async updateMe({ commit }, payload) {
    const { data } = await updateMe(payload);
    console.log(data);
    commit('SET_USER', data);
  },
};

// mutations
const mutations = {
  SET_CLIENTS(state, clients) {
    state.clients = clients;
  },
  SET_CLIENT(state, client) {
    state.client = client;
  },
  SET_STAFFS(state, clients) {
    state.staffs = clients;
  },
  SET_CASE_STAFFS(state, clients) {
    state.case_staffs = clients;
  },
  SET_STAFF(state, client) {
    state.staff = client;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  LOGOUT(state) {
    state.user = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
