import axios from 'axios';
import authAxios from './http';
import { stringify } from 'qs';
import { DateTime } from 'luxon';

export const createTemporaryCase = (data) =>
  axios.post(`${process.env.VUE_APP_API_URL}/temporary_cases`, data);

export const getPropertyTypes = () =>
  axios.get(`${process.env.VUE_APP_API_URL}/property-types`);

export const getAppTypes = () =>
  axios.get(`${process.env.VUE_APP_API_URL}/app-types`);


export const getCases = ({ start = 0, limit = 10, id }) => {
  let staffFilter = '';
  if (id !== undefined) {
    staffFilter = '&staffs=' + id;
  }
  return authAxios.get(
    `cases?_sort=created_at:desc&_start=${start === 1 || start === 0 ? 0 : (start - 1) * 10
    }&_limit=${limit}${staffFilter}`
  );
}
export const getLegelFees = (start = 0, limit = 10) =>{
 if(typeof start === 'string'){
  return axios.get(
    `${process.env.VUE_APP_API_URL}/legel-fees?type_of_sale_eq=${start}&_sort=created_at:desc`
  )
 }else{
  return axios.get(
    `${process.env.VUE_APP_API_URL}/legel-fees?_sort=created_at:desc&_start=${start === 1 || start === 0 ? 0 : (start - 1) * 10
    }&_limit=${limit}`
  )
 }
};
export const getAllLegelFees = () =>
    axios.get(
     `${process.env.VUE_APP_API_URL}/legel-fees?_sort=price_from:ASC`
   );
export const getMountlyCasesCount = () => {
  const query = stringify({
    created_at_gte: DateTime.local()
      .startOf('month')
      .toISODate(),
    created_at_lte: DateTime.local()
      .endOf('month')
      .toISODate(),
  });
  return new authAxios.get(`cases/count?${query}`);
};

export const getCase = (id) => authAxios.get(`cases/${id}`);

export const getCasesCount = (id = undefined) => {
  let staffFilter = '';
  if (id !== undefined) {
    staffFilter = '?staffs=' + id;
  }
  return authAxios.get(`cases/count${staffFilter}`)
};
export const getLegelFeesCount = () => axios.get(`${process.env.VUE_APP_API_URL}/legel-fees/count`);

export const getMyCases = () => authAxios.get(`cases/me`);

export const createCaseNote = (data) =>
  authAxios.post(`case-notes`, {
    note: data.note,
    case: data.case,
  });

export const getStages = () => authAxios.get(`stages`);

export const createLegalFee = (data) =>
  authAxios.post(`legel-fees`, {
    name: data.name,
    price_from: data.price_from,
    price_to: data.price_to,
    fees: data.fees,
    admin: data.admin,
    buyer: data.buyer,
    type_of_sale: data.type_of_sale,
    land_registry_fee: data.land_registry_fee,
    land_registry_search: data.land_registry_search,
    search_packs: data.search_packs,
    vat_fee: data.vat_fee,
    mortgage_fee: data.mortgage_fee,
    id_verfication_fee: data.id_verfication_fee,
    electronic_transfer_fee: data.electronic_transfer_fee,
    archive_fee: data.archive_fee,
    lawyer_fee: data.lawyer_fee,
    storage_fee: data.storage_fee,
    mortgage_discharge_fee: data.mortgage_discharge_fee,
    draft_contract_fee: data.draft_contract_fee,
    ownership_fee: data.ownership_fee,
    bankruptcy_fee: data.bankruptcy_fee,
    transfer_fee: data.transfer_fee
  }
  );

export const getLegalFee = (id) => authAxios.get(`legel-fees/${id}`);

export const updateLegalFee = (data) =>
  authAxios.put(`legel-fees/${data.id}`, {
    name: data.name,
    price_from: data.price_from,
    price_to: data.price_to,
    fees: data.fees,
    admin: data.admin,
    buyer: data.buyer,
    type_of_sale: data.type_of_sale,
    land_registry_fee: data.land_registry_fee,
    land_registry_search: data.land_registry_search,
    search_packs: data.search_packs,
    vat_fee: data.vat_fee,
    mortgage_fee: data.mortgage_fee,
    id_verfication_fee: data.id_verfication_fee,
    electronic_transfer_fee: data.electronic_transfer_fee,
    archive_fee: data.archive_fee,
    lawyer_fee: data.lawyer_fee,
    storage_fee: data.storage_fee,
    mortgage_discharge_fee: data.mortgage_discharge_fee,
    draft_contract_fee: data.draft_contract_fee,
    ownership_fee: data.ownership_fee,
    bankruptcy_fee: data.bankruptcy_fee,
    transfer_fee: data.transfer_fee
  }
  );
export const getEmails = (start = 0, limit = 10) =>
  authAxios.get(
    `emails?_sort=created_at:desc&_start=${start === 1 || start === 0 ? 0 : (start - 1) * 10
    }&_limit=${limit}`
  );
export const getEmailsCount = () =>
  authAxios.get(`emails/count`)


export const deleteLegalFee = (id) => authAxios.delete(`legel-fees/${id}`);

export const createEmailsLog = (data) => {
  return authAxios.post(`emails`, {
    client_name: data.client_name,
    client_email: data.client_email,
    subject: data.subject,
    text: data.text,
    type_of_sale: data.type_of_sale,
    property_price: data.property_price,
    discount_fee: data.discount_fee,
    preview_price: data.preview_price,
    legel_fees: data.legel_fees,
    land_registry_fee: data.land_registry_fee,	
    land_registry_search: data.land_registry_search,
    search_packs: data.search_packs,
    mortgage_fee:  data.mortgage_fee,
    vat_fee:   data.vat_fee,
    id_verfication_fee: data.id_verfication_fee,
    electronic_transfer_fee:  data.electronic_transfer_fee,
    archive_fee:  data.archive_fee,
    lawyer_fee:  data.lawyer_fee,
    sdlt_fee: data.sdlt_fee,
    stamp_duty_fee: data.stamp_duty_fee
  }
  )
};
