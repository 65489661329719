import axiosAuth from 'axios';
import axios from './http';
import decode from 'jwt-decode';
const AUTH_TOKEN_KEY = 'user-token';
const R = 'r';

export const loginUser = (user) =>
  new Promise((resolve, reject) => {
    axiosAuth({
      url: process.env.VUE_APP_API_URL + '/auth/local',
      data: user,
      method: 'POST',
    })
      .then((resp) => {
        const token = resp.data.jwt;
        setAuthToken(token);
        resolve(resp);
      })
      .catch((err) => {
        clearAuthToken();
        reject(err);
      });
  });

export const resetPassword = (user) =>
  new Promise((resolve, reject) => {
    axiosAuth({
      url: process.env.VUE_APP_API_URL + '/auth/reset-password',
      data: user,
      method: 'POST',
    })
      .then((resp) => {
        const token = resp.data.jwt;
        setAuthToken(token);
        resolve(resp);
      })
      .catch((err) => {
        clearAuthToken();
        reject(err);
      });
  });

export const changePassword = (data) => axios.post('auth/password', data);

export const forgotPassword = (user) =>
  axiosAuth({
    url: process.env.VUE_APP_API_URL + '/auth/forgot-password',
    data: user,
    method: 'POST',
  });

export const getMe = () => axios.get('users/getMe');

export const updateMe = (data) => axios.put(`users/updateMe`, data);

export const registerUser = (user) => axios.post('auth/local/register', user);

export const getVerificationResult = (registration_id) =>
  axios.post('auth/verification/result', { registration_id });

export const verification = (actionId) =>
  axios.post('auth/verification', {
    actionId,
  });

export const checkEmailExists = (email) =>
  axiosAuth.post(process.env.VUE_APP_API_URL + '/auth/verification/email', {
    email,
  });

export function logoutUser() {
  clearAuthToken();
}

export function setAuthToken(token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  localStorage.setItem(AUTH_TOKEN_KEY, token);
}

export function getAuthToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function clearAuthToken() {
  axios.defaults.headers.common['Authorization'] = '';
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(R);
  localStorage.removeItem('auth-user');
}

export function isLoggedIn() {
  let authToken = getAuthToken();
  return !!authToken && !isTokenExpired(authToken);
}

export function getUserInfo() {
  if (isLoggedIn()) {
    return decode(getAuthToken());
  }
}

function getTokenExpirationDate(encodedToken) {
  let token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }

  let date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

function isTokenExpired(token) {
  let expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}
