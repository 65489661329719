import axios from './http';

export const getClients = ({ start, limit, search }) =>
  axios.get('users', {
    params: {
      _sort: 'created_at:desc',
      _start: start === 1 || start === 0 ? 0 : (start - 1) * 10,
      'role.name': 'Client',
      _limit: limit,
      _q: search,
    },
  });

export const getClientsCount = (search) =>
  axios.get(`users/count/?role.name=Client`, {
    params: {
      _q: search,
    },
  });
export const getStaffs = ({ start, limit, search }) =>
  axios.get('users', {
    params: {
      _sort: 'created_at:desc',
      _start: start === 1 || start === 0 ? 0 : (start - 1) * 10,
      'role.name': 'Staff',
      _limit: limit,
      _q: search,
    },
  });

export const getStaffsCount = (search) =>
  axios.get(`users/count`, {
    params: {
      _q: search,
      'role.name': 'Staff'
    },
  });
export const getCaseStaffs = ({ caseId }) =>
  axios.get('users', {
    params: {
      'role.name': 'Staff',
      'assigned_cases': caseId,
    },
  });

export const getCaseStaffsCount = (search) =>
  axios.get(`users/count`, {
    params: {
      _q: search,
      'assigned_cases': 2,
      'role.name': 'Staff'
    },
  });
export const createStaff = (data) =>
  axios.post(`users`, {
    email: data.email,
    username: data.username,
    info: {
      email_address: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      address: {
        street_address: data.street_address,
        city: data.city,
        state: data.state,
        country: data.country,
        postcode: data.postcode
      }
    },
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
    password: data.password,
    role: 5
  });
export const updateStaff = async (data) => {
  let obj = {
    email: data.email,
    username: data.username,
    info: {
      email_address: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      address: {
        street_address: data.street_address,
        city: data.city,
        state: data.state,
        country: data.country,
        postcode: data.postcode
      }
    },
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
  }
  if (data.password !== undefined) {
    obj.password = data.password;
  }
  await axios.put(`users/${data.id}`, obj);
}
export const addCaseStaff = async (staff, caseId) => {
  let obj = {
    assigned_cases: caseId
  }
  await axios.put(`users/${staff}`, obj);
}
export const removeCaseStaff = async (staff, caseId) => {
  console.log("caseId", caseId)
  let obj = {
    assigned_cases: caseId
  }
  await axios.put(`users/${staff}`, obj);
}
export const deleteStaff = (id) => axios.delete(`users/${id}`);

export const getClient = (id) => axios.get(`users/${id}`);
export const getStaff = (id) => axios.get(`users/${id}`);
